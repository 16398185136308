import { ContentCopy, Delete, Edit } from '@mui/icons-material';
import { Avatar, MenuItem } from '@mui/material';
import { capitalCase } from 'change-case';
import CopyToClipboard from 'react-copy-to-clipboard';
import Link from 'src/components/shared/Link';
import MagicTable from 'src/components/shared/MagicTable/MagicTable';
import MoreMenu from 'src/components/shared/MoreMenu';
import useURLSearchParams from 'src/lib/hooks/useURLSearchParams';
import { CardShard } from 'src/lib/types/cardShard';
import { getImageURL } from 'src/lib/utils/nft';
import Routes from 'src/routes/Routes';
import { FetchShardsQueryParams } from '../../view/SelectList/useView';

interface ShardsTableProps {
  data: CardShard[];
  isLoading: boolean;

  params: FetchShardsQueryParams;

  handleUpdate?: (shard: CardShard) => void;
  handleDelete?: (shardUuid: string) => void;
  handleRemoveShard?: (ShardUuid: string) => void;
}

export default function ShardsTable({
  data,
  isLoading,
  params,
  handleUpdate,
  handleDelete,
  handleRemoveShard,
}: ShardsTableProps): JSX.Element {
  const { addParam, removeParam } = useURLSearchParams();

  return (
    <MagicTable
      rows={data}
      getRowId={(r) => r.uuid}
      loading={isLoading}
      paginationMode="server"
      pageSize={params.size}
      page={params.page}
      onPageChange={(newPage) => {
        addParam('page', newPage);
      }}
      onPageSizeChange={(newSize) => {
        addParam('size', newSize);
      }}
      onSortModelChange={(model) => {
        const currentModel = model[0];
        if (currentModel) {
          addParam('sortBy', currentModel.field);
          if (currentModel.sort) addParam('order', currentModel.sort);
        } else {
          removeParam('order');
          removeParam('sortBy');
        }
      }}
      columns={[
        {
          field: 'nft.name',
          headerName: 'NFT',
          sortable: true,
          renderCell: ({ row }) => (
            <>
              <Avatar
                alt={row.nft.name}
                src={getImageURL(row.nft.fileUrl)}
                variant="rounded"
                sx={{ mr: 2 }}
              />
              <Link
                to={Routes.nft(row.nft.uuid)}
                color={row.nft.name ? 'inherit' : 'text.secondary'}
                underline="hover"
                display="inline-block"
              >
                {row.nft.name ?? 'empty'}{' '}
              </Link>
            </>
          ),
        },
        {
          field: 'rarity',
          headerName: 'Rarity',
          sortable: true,
          valueFormatter: ({ value }) => capitalCase(value),
        },
        {
          field: 'amountToCreate',
          headerName: 'Amount To Create',
          sortable: true,
        },
        {
          field: 'actions',
          headerName: 'Actions',
          sortable: false,
          flex: 0,

          renderCell({ row }) {
            return (
              <MoreMenu>
                <CopyToClipboard text={row.uuid}>
                  <MenuItem aria-label="copy feed uuid">
                    <ContentCopy />
                    Copy UUID
                  </MenuItem>
                </CopyToClipboard>
                {handleUpdate && (
                  <MenuItem
                    onClick={() => {
                      handleUpdate(row.uuid);
                    }}
                  >
                    <Edit />
                    Edit
                  </MenuItem>
                )}
                {handleDelete && (
                  <MenuItem
                    onClick={() => {
                      handleDelete(row.uuid);
                    }}
                  >
                    <Delete />
                    Delete
                  </MenuItem>
                )}
                {handleRemoveShard && (
                  <MenuItem
                    onClick={() => {
                      handleRemoveShard(row.uuid);
                    }}
                  >
                    <Delete />
                    Remove
                  </MenuItem>
                )}
              </MoreMenu>
            );
          },
        },
      ]}
    />
  );
}
