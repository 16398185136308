import { ReportPlatform, ReportStatus } from 'src/lib/types/report';

export const REPORT_COLUMNS = [
  {
    id: 'createdAt',
    label: 'Date',
    isSortable: true,
  },
  {
    id: 'status',
    label: 'Status',
    isSortable: true,
  },
  {
    id: 'userUuid',
    label: 'User',
    isSortable: false,
  },
  {
    id: 'details',
    label: 'Details',
    isSortable: true,
  },
  {
    id: 'reason',
    label: 'Category',
    isSortable: false,
  },
  {
    id: 'objectUuid',
    label: 'Object',
    isSortable: false,
  },
  {
    id: 'ownerUuid',
    label: 'Reported User',
    isSortable: false,
  },
  {
    id: 'platform',
    label: 'Platform',
    isSortable: true,
  },
  {
    id: 'version',
    label: 'Version',
    isSortable: true,
  },
  {
    id: '',
    label: '',
    isSortable: false,
  },
];

export const SEARCH_FOR_OPTIONS = [
  {
    value: 'name',
    label: 'Name',
  },
  {
    value: 'email',
    label: 'Email',
  },
  {
    value: 'details',
    label: 'Details',
  },
  {
    value: 'objectUuid',
    label: 'Object UUID',
  },
  {
    value: 'collectionUuid',
    label: 'Pack UUID',
  },
  {
    value: 'platform',
    label: 'Platform',
  },
  {
    value: 'version',
    label: 'Version',
  },
];

export const REPORT_STATUS_OPTIONS = [
  { value: 'all', label: 'All', color: 'default' },
  {
    value: ReportStatus.TAKE_SOME_ACTION,
    label: 'Take Action',
    color: 'error',
  },
  { value: ReportStatus.UNREAD, label: 'Unread', color: 'warning' },
  { value: ReportStatus.ACTION_TAKED, label: 'Action Taken', color: 'success' },
  // { value: ReportStatus.IGNORED, label: 'Ignored', color: 'secondary' },
  {
    value: ReportStatus.NO_ACTION_NEEDED,
    label: 'No Action Needed',
    color: 'info',
  },
];

export const getReportPlatform = (platform: ReportPlatform) => {
  switch (platform) {
    case ReportPlatform.IOS_STICKERS:
    case ReportPlatform.IOS_STICKY_FREE:
      return 'iOS';
    case ReportPlatform.ANDROID_STICKERS:
    case ReportPlatform.ANDROID_STICKY_FREE:
    case ReportPlatform.ANDROID_STICKY:
      return 'Android';
    case ReportPlatform.WEB_STICKY:
      return 'Web';
    case ReportPlatform.IOS_RED:
      return 'IOS App#2';
    default:
      return '-';
  }
};
