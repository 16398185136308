import { Info, Warning } from '@mui/icons-material';
import { Box, Button, IconButton, Tooltip, Typography } from '@mui/material';
import { useState } from 'react';
import Flex from 'src/components/shared/Flex';
import PreviewQrCodeModal from 'src/components/shared/QrCodeUrlPreviewer/QrCodeModal';
import useModalStore from 'src/lib/stores/useModalStore';
import { SGMChat, SGMChatMessage } from 'src/lib/types/sgmChatMessage';
import { getPlayGameUrl } from 'src/lib/utils/utils';
import Iconify from 'src/template/components/Iconify';
import Image from 'src/template/components/Image';
import { uploadHTMLToFirebase } from '../../utils/firebaseUpload';
import SGMMessageLogModal from '../logModal';

interface MessageBubbleProps {
  chatInfo: SGMChat;
  message: SGMChatMessage;
}

export default function MessageBubble({
  chatInfo,
  message: {
    isUserSended,
    metadata,
    createdAt,
    id,
    values,
    deletedAt,
    version,
    isPublished,
  },
}: MessageBubbleProps) {
  const timestamp = new Date(createdAt);
  const { openModal, closeModal } = useModalStore();
  const hasHTMLContent = metadata && metadata.metadata;
  const [isUploading, setIsUploading] = useState<boolean>(false);

  const handleDownloadHtmlContent = async () => {
    if (metadata && metadata.metadata) {
      const htmlContent = metadata.metadata;

      try {
        const filename = `game-${chatInfo.id}.html`;
        const blob = new Blob([htmlContent], { type: 'text/html' });
        const url = URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      } catch (error) {
        console.error('Failed to download HTML content:', error);
      }
    }
  };

  const handleOpenContent = async () => {
    if (metadata && metadata.metadata) {
      const htmlContent = metadata.metadata;

      try {
        setIsUploading(true);

        const filename = `game-${chatInfo.id}`;

        const url = await uploadHTMLToFirebase(htmlContent, filename);
        const base64Url = btoa(url);

        const finalUrl = getPlayGameUrl({
          game: base64Url,
          closeButton: true,
        });

        openModal(<PreviewQrCodeModal title="Preview Game" url={finalUrl} />);
      } catch (error) {
        console.error('Failed to upload HTML and preview the game:', error);
      } finally {
        setIsUploading(false);
      }
    }
  };

  const is12HourFormat =
    timestamp
      .toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
      .includes('AM') ||
    timestamp
      .toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
      .includes('PM');

  const handleOpenInfo = () => {
    openModal(<SGMMessageLogModal messageUuid={id} title="Logs" />);
  };

  return (
    <Flex
      alignSelf={isUserSended ? 'flex-end' : 'flex-start'}
      px={4}
      bgcolor={'rgba(255, 255, 255, 0.08)'}
      color="white"
      fontSize="14px"
      lineHeight={1.6}
      fontWeight={500}
      borderRadius="12px"
      py={2.5}
      maxWidth={'80%'}
      flexDirection={'column'}
      position="relative"
      border="1px solid transparent"
    >
      {values?.body && (
        <Typography whiteSpace="pre-wrap" mr={3} mb={2}>
          {values?.body}
        </Typography>
      )}

      {values?.instruction && (
        <>
          <Image src={values?.url} alt="Instruction" maxWidth={200} />
          <Typography whiteSpace="pre-wrap" mr={3}>
            {values?.instruction}
          </Typography>
        </>
      )}

      {/* Ícone de Informação fixo no canto superior direito */}
      {isUserSended && (
        <Tooltip title="Open raw content">
          <IconButton
            size="small"
            sx={{
              color: 'white',
              position: 'absolute',
              top: 8,
              right: 8,
            }}
            onClick={handleOpenInfo}
          >
            <Info />
          </IconButton>
        </Tooltip>
      )}

      {hasHTMLContent && chatInfo && (
        <Flex
          justifyContent="space-between"
          alignItems="center"
          pt={4}
          pb={8}
          mt={4}
          borderTop="1px solid rgba(255, 255, 255, 0.1)"
          flexGrow={1}
          width={'100%'}
        >
          <Flex gap={2.5} alignItems="center">
            {/* <Image
              src={
                chatInfo.metadata?.icon || '/static/img/game/ai-game-maker.png'
              }
              alt={chatInfo.metadata?.title || 'AI Game Maker'}
              sx={{
                width: '35px',
                height: '35px',
                borderRadius: '8px',
              }}
            /> */}
            <Typography fontSize="14px" fontWeight={700}>
              {version
                ? `Version ${version} ${isPublished ? '(Published)' : ''}`
                : chatInfo.metadata?.title || 'Your Game'}
            </Typography>
          </Flex>
          <Flex gap={1}>
            <Button
              onClick={handleOpenContent}
              variant="outlined"
              disabled={isUploading}
              sx={{
                '&:hover[disabled], &:hover[aria-disabled=true]': {
                  backgroundColor: 'white', // Or the desired color
                },
                backgroundColor: 'white',
                color: 'black',
              }}
            >
              <Iconify icon="play-circle" />
              {isUploading ? 'Uploading...' : 'Preview'}
            </Button>
            <Button
              onClick={handleDownloadHtmlContent}
              variant="outlined"
              sx={{
                backgroundColor: 'white',
                color: 'black',
              }}
            >
              <Iconify icon="play-circle" />
              Download
            </Button>
          </Flex>
        </Flex>
      )}

      <Flex position="absolute" bottom="10px" right="10px" gap={2}>
        {deletedAt && (
          <Box
            fontSize="10px"
            fontWeight={500}
            color="rgba(255, 255, 255, 0.3)"
          >
            <Tooltip title="This message was reverted">
              <Warning fontSize="small" color="error" />
            </Tooltip>
          </Box>
        )}
        <Typography
          fontSize="10px"
          fontWeight={500}
          color="rgba(255, 255, 255, 0.3)"
        >
          {timestamp.toLocaleTimeString([], {
            hour: '2-digit',
            minute: '2-digit',
          })}
        </Typography>
      </Flex>
    </Flex>
  );
}
