import NFTPackForm from 'src/components/modules/NFTPacks/components/Forms/NftPack';
import { CardShard } from 'src/lib/types/cardShard';
import useUpdateNFTPackView from './useView';

type Props = {
  shard: CardShard;
};

export default function UpdateNftPackView({ shard }: Props): JSX.Element {
  const { handleUpdateNftPack } = useUpdateNFTPackView();
  return (
    <NFTPackForm
      defaultValues={{
        name: '',
        amountOfNftsToRecharge: 2,
        type: '',
        productUuid: '',
      }}
      onSubmit={async (values) => {
        handleUpdateNftPack(shard.uuid, values);
      }}
      title="Update NFT Pack"
    />
  );
}
