import { useNavigate, useParams } from 'react-router';
import { DEFAULT_INITIAL_PAGE_SIZE } from 'src/lib/constants/pagination';
import useURLSearchParams from 'src/lib/hooks/useURLSearchParams';
import Routes from 'src/routes/Routes';
import useTabs from 'src/template/hooks/useTabs';
import { fetchMakerDialog } from '../../services/fetchMakerDialog';
import { fetchMakerMessagesInfinite } from '../../services/fetchMakerMessagesInfinite';

export default function useMakerDialogDetails(paramPromptDialogUuid?: string) {
  const { promptDialogUuid: queryPromptDialogUuid } = useParams<{
    promptDialogUuid: string;
  }>();
  const promptDialogUuid = paramPromptDialogUuid || queryPromptDialogUuid;
  const navigate = useNavigate();
  if (!promptDialogUuid) {
    navigate(Routes.sgm.dialogs);
  }
  const { currentTab, onChangeTab } = useTabs('all', {
    queryName: 'tab',
  });

  const { allSearchParams } = useURLSearchParams(initialParams);

  const params = {
    page: 0, // +(allSearchParams.page ?? initialParams.page),
    size: 1000, // +(allSearchParams.size ?? initialParams.size),
  };

  const { data, isLoading } = fetchMakerDialog(promptDialogUuid!);
  const { messages: messagesData, handleFetchMore } =
    fetchMakerMessagesInfinite(promptDialogUuid!);

  return {
    data,
    isLoading,
    messagesData,
    params,
    currentTab,
    onChangeTab,
    handleFetchMore,
  };
}

const initialParams = {
  page: '0',
  size: DEFAULT_INITIAL_PAGE_SIZE.toString(),
};

export type FetchMakerDialogsQueryParams = {
  page: number;
  size: number;
};
