import { OpenInNew } from '@mui/icons-material';
import { Card, CardContent, CardHeader, Stack } from '@mui/material';
import Flex from 'src/components/shared/Flex';
import Link from 'src/components/shared/Link';
import MagicTableGameCell from 'src/components/shared/MagicTable/GameCell';
import MagicTableUserCell from 'src/components/shared/MagicTable/UserCell';
import SimpleTable from 'src/components/shared/SimpleTable/SimpleTable';
import { SGMChatWithAssets } from 'src/lib/types/sgmChatMessage';
import { formatDollar } from 'src/lib/utils/formatDollar';
import Routes from 'src/routes/Routes';
import Label from 'src/template/components/Label';
import LoadingScreen from 'src/template/components/LoadingScreen';

interface MakerDialogStatsCardInfosProps {
  chat?: SGMChatWithAssets;
}

export default function MakerDialogStatsCardInfos({
  chat,
}: MakerDialogStatsCardInfosProps) {
  if (!chat) return <LoadingScreen />;
  const {
    messagesInfo: { totalUserMessages, totalCount, totalRevertedMessages },
    costsInfo: { totalCost, avgCostPerMessage },
    errorsInfo: { errorsCount, messagesWithError },
    user,
    game,
    hasReport,
  } = chat || {};
  return (
    <Flex
      justifyContent={'space-between'}
      gap={3}
      flexWrap={'wrap'}
      alignItems={'start'}
    >
      <Card sx={{ flex: 1 }}>
        <Flex justifyContent="space-between" alignItems="center" pr={2}>
          <CardHeader title="Details" />
        </Flex>
        <CardContent>
          <SimpleTable
            border
            rounded
            data={[
              {
                id: 'user',
                label: 'User',
                value: <MagicTableUserCell user={user} />,
              },
              {
                id: 'game',
                label: 'Game',
                value: game?.uuid ? (
                  <MagicTableGameCell game={game} />
                ) : (
                  <Label color="error">Unpublished</Label>
                ),
              },
              {
                id: 'usedTemplate',
                label: 'Used Template',
                value: (
                  <Label color={chat.usedTemplate ? 'success' : 'error'}>
                    {chat.usedTemplate ? 'Yes' : 'No'}
                  </Label>
                ),
              },
            ]}
          />
        </CardContent>
      </Card>

      <Card sx={{ flex: 1 }}>
        <Flex justifyContent="space-between" alignItems="center" pr={2}>
          <CardHeader title="Summary" />
        </Flex>
        <CardContent>
          <SimpleTable
            border
            rounded
            data={[
              {
                id: 'totalCost',
                label: 'Total Cost',
                value: formatDollar(totalCost, 3),
              },
              {
                id: 'messagesByUser',
                label: 'User Prompts',
                value: totalUserMessages,
              },
            ]}
          />
        </CardContent>
      </Card>

      <Card sx={{ flex: 1 }}>
        <Flex justifyContent="space-between" alignItems="center" pr={2}>
          <CardHeader title="Analysis" />
        </Flex>
        <CardContent>
          <SimpleTable
            border
            rounded
            data={[
              {
                id: 'totalRevertedMessages',
                label: 'N° of Reverted Messages',
                value: totalRevertedMessages,
              },
              {
                id: 'messagesWithError',
                label: 'N° of Messages with Error',
                value: messagesWithError,
              },
              {
                id: 'hasReport',
                label: 'Has Report',
                value: (
                  <Stack direction="row" alignItems="center">
                    <Label color={hasReport ? 'error' : 'success'}>
                      {hasReport ? 'Yes' : 'No'}{' '}
                    </Label>
                    {hasReport && (
                      <Link to={`${Routes.reports}?objectUuid=${chat.id}`}>
                        <OpenInNew />
                      </Link>
                    )}
                  </Stack>
                ),
              },
            ]}
          />
        </CardContent>
      </Card>
    </Flex>
  );
}
