import { useCallback } from 'react';
import { DeepPartial } from 'react-hook-form';
import { fetcher } from 'src/lib/services/api/fetcher';
import { PaginatedResponse } from 'src/lib/types/PaginatedResponse';
import { ResponseWithStatus } from 'src/lib/types/ResponseWithStatus';
import { SGMChatMessage } from 'src/lib/types/sgmChatMessage';
import { parseSearchParams } from 'src/lib/utils/parseSearchParams';
import useSWRInfinite from 'swr/infinite';

const PAGINATION_SIZE = 25;

export type PossibleParams = {
  limit: number | string;
  offset: number | string;
};

export const fetchMakerMessagesInfinite = (
  promptDialogUuid: string,
  filter?: DeepPartial<PossibleParams>
) => {
  const getKey = (
    pageIndex: number,
    previousPageData: ResponseWithStatus<{ messages: SGMChatMessage[] }>
  ) => {
    if (
      previousPageData &&
      previousPageData?.response?.messages.length < PAGINATION_SIZE
    )
      return null; // Para quando a página retorna menos de 50 mensagens

    const offset = pageIndex * PAGINATION_SIZE;
    const parsedParams = parseSearchParams({
      ...filter,
      offset,
      limit: PAGINATION_SIZE,
      order: 'desc',
      orderBy: 'createdAt',
    });
    return `admin/game-maker-chats/${promptDialogUuid}/messages?${parsedParams}`;
  };

  const {
    setSize,
    data,
    error,
    size,
    mutate: reload,
    isValidating,
  } = useSWRInfinite<PaginatedResponse<SGMChatMessage[]>, Error>(
    getKey,
    fetcher
  );

  const hasReachedEnd = Boolean(
    data && data[data.length - 1].body.length < PAGINATION_SIZE
  );

  const handleFetchMore = useCallback(() => {
    if (isValidating) return;
    if (!hasReachedEnd) setSize(size + 1);
  }, [isValidating, hasReachedEnd, size, setSize]);

  const dataCopy = data?.slice().flatMap((d) => d.body) ?? [];

  return {
    messages: dataCopy,
    status: { error, isValidating, hasReachedEnd },
    handleFetchMore,
  };
};
