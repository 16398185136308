import { DeepPartial } from 'react-hook-form';
import { CardShard } from 'src/lib/types/cardShard';
import { parseSearchParams } from 'src/lib/utils/parseSearchParams';
import useSWR, { SWRResponse } from 'swr';

export type PossibleParams = {
  page: number | string;
  size: number | string;
  order: Record<string, string>;
  filter: {
    [key: string]: {
      like: string;
      eq: string;
    };
  };
};

export const fetchCardShards = (
  filter?: DeepPartial<PossibleParams>
): SWRResponse<CardShard[]> => {
  const parsedParams = parseSearchParams(filter ?? {});
  const response = useSWR<CardShard[], Error>(
    `/admin/card-shard/?${parsedParams}`
  );

  return response;
};
