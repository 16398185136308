import { useCallback, useEffect, useRef } from 'react';
import Flex from 'src/components/shared/Flex';
import { SGMChat, SGMChatMessage } from 'src/lib/types/sgmChatMessage';
import MessageBubble from './MessageBubble';

interface ChatMessagesProps {
  chatInfo: SGMChat;
  messages: SGMChatMessage[];
  loadMoreMessages?: () => void;
}

export default function ChatMessages({
  messages,
  loadMoreMessages,
  chatInfo,
}: ChatMessagesProps) {
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.scrollTop = container.scrollHeight;
    }
  }, [messages]);

  const handleScroll: React.UIEventHandler<HTMLDivElement> = useCallback(
    (e) => {
      const { scrollTop, clientHeight, scrollHeight } = e.currentTarget;
      const height = scrollTop - clientHeight;
      const zone = 100;
      const diff = scrollHeight + height;
      const isAtTop = diff <= zone;

      if (isAtTop && loadMoreMessages) {
        loadMoreMessages();
      }
    },
    [loadMoreMessages]
  );

  return (
    <Flex
      ref={containerRef}
      px={2}
      flex={1}
      flexDirection={'column-reverse'}
      width={'100%'}
      gap={4}
      my={1}
      overflow={'auto'}
      sx={{
        '&::-webkit-scrollbar': {
          width: '5px',
        },
        '&::-webkit-scrollbar-track': {
          background: 'transparent',
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: '#888', // Cor da barra de rolagem
          borderRadius: '5px',
        },
        '&::-webkit-scrollbar-thumb:hover': {
          backgroundColor: '#555', // Cor ao passar o mouse sobre a barra
        },
      }}
      onScroll={handleScroll}
    >
      {messages.length > 0 && <Flex flex="1" />}
      {messages.length > 0 &&
        messages.map((msg) => (
          <MessageBubble key={msg.id} chatInfo={chatInfo} message={msg} />
        ))}
    </Flex>
  );
}
