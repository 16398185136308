import { Add } from '@mui/icons-material';
import {
  Autocomplete,
  Button,
  Card,
  CardContent,
  InputAdornment,
  TextField,
  debounce,
} from '@mui/material';
import Flex from 'src/components/shared/Flex';
import useURLSearchParams from 'src/lib/hooks/useURLSearchParams';
import HeaderBreadcrumbs from 'src/template/components/HeaderBreadcrumbs';
import Iconify from 'src/template/components/Iconify';
import ShardsTable from '../../components/shardsTable';
import useCardShardsView from './useView';

/*
const STATUS_TABS = [
  { value: 'all', label: 'All' },
  { value: 'draft', label: 'Draft' },
  { value: 'ready', label: 'Ready' },
];
*/

const searchOptions = [
  {
    label: 'NFT Name',
    query: 'nft.name',
  },
];

export default function CardShards() {
  const {
    data,
    isLoading,
    handleOpenCreateModal,
    handleOpenUpdateModal,
    handleOpenDeleteModal,
    onChangeStatus,
    status,
    page,
    size,
    order,
    sortBy,
    searchBy,
    query,
  } = useCardShardsView();

  const { addParam, removeParam } = useURLSearchParams();

  function handleChangeSearch(value: string, param: string = 'query') {
    if (!value) removeParam(param);
    else addParam(param, value);
  }

  const debouncedSearch = debounce(handleChangeSearch, 300);

  return (
    <>
      <HeaderBreadcrumbs
        heading="Card Shards"
        links={[{ name: 'Card Shards' }]}
        action={
          <Button
            variant="contained"
            color="secondary"
            onClick={handleOpenCreateModal}
            startIcon={<Add />}
          >
            Add Card Shard
          </Button>
        }
      />
      <Card>
        {/* <Flex sx={{ px: 2, bgcolor: 'background.neutral' }}>
          <Tabs
            allowScrollButtonsMobile
            variant="scrollable"
            scrollButtons="auto"
            value={status}
            onChange={onChangeStatus}
          >
            {STATUS_TABS.map((tab) => (
              <Tab
                disableRipple
                key={tab.value}
                label={tab.label}
                value={tab.value}
              />
            ))}
          </Tabs>
          </Flex> */}
        <Flex gap={2} p={2}>
          <Autocomplete
            options={searchOptions}
            fullWidth
            renderInput={(params) => (
              <TextField label="Search By" {...params} />
            )}
            getOptionLabel={(p) => p.label}
            sx={{ maxWidth: 250 }}
            defaultValue={searchOptions.find((o) => o.query === searchBy)}
            key={searchBy}
            onChange={(_, value) => {
              if (!value) removeParam('searchBy');
              else addParam('searchBy', value.query);
            }}
          />

          <TextField
            fullWidth
            label="Search"
            defaultValue={query}
            type="search"
            onChange={(e) => {
              const { value } = e.target;
              debouncedSearch(value);
            }}
            key={query}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Iconify
                    icon="eva:search-fill"
                    sx={{ color: 'text.disabled', width: 20, height: 20 }}
                  />
                </InputAdornment>
              ),
            }}
          />
        </Flex>

        <CardContent>
          <ShardsTable
            data={data ?? []}
            isLoading={isLoading}
            params={{
              page,
              size,
              orderBy: sortBy ?? 'nft.name',
              sortOrder: order ?? 'asc',
            }}
            handleUpdate={handleOpenUpdateModal}
            handleDelete={handleOpenDeleteModal}
          />
        </CardContent>
      </Card>
    </>
  );
}
