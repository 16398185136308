import { fetchDevices } from 'src/components/modules/Devices/services/fetchDevices';
import { fetchRankingFilterOptions } from 'src/components/modules/Users/services/fetchRankingFilterOptions';
import { CommonDateStrings } from 'src/lib/constants/CommonDates';
import { DEFAULT_INITIAL_PAGE_SIZE } from 'src/lib/constants/pagination';
import useURLSearchParams from 'src/lib/hooks/useURLSearchParams';
import { IncludeExcludeAll } from 'src/lib/utils/includeExcludeFilterUtils';
import useTabs from 'src/template/hooks/useTabs';

export default function useDevicesView() {
  const { onChangeTab, currentTab } = useTabs('all', { queryName: 'active' });
  const { allSearchParams, addParam, removeParam } =
    useURLSearchParams(InitialParams);
  const { data, isLoading, error } = fetchDevices({
    page: allSearchParams.page,
    size: allSearchParams.size,
    orderBy: allSearchParams.orderBy,
    sortOrder: allSearchParams.order,
    filter: {
      active: currentTab !== 'all' ? currentTab : undefined,
      country: allSearchParams.country,
      platform: allSearchParams.platform,
      AnonymousUsers: allSearchParams.AnonymousUsers as IncludeExcludeAll,
      startDate: allSearchParams.startDate,
      endDate: allSearchParams.endDate,
    },
  });
  const { data: filterOptions, loading: loadingFilterOptions } =
    fetchRankingFilterOptions();

  function handleChangeFilter(field: string, value: string) {
    if (!value.trim().length) removeParam(field);
    else addParam(field, value);
  }

  return {
    data,
    isLoading,
    currentTab,
    onChangeTab,
    handleChangeFilter,
    filterOptions,
    loadingFilterOptions,
    error,
    filterValues: {
      AnonymousUsers: allSearchParams.AnonymousUsers as IncludeExcludeAll,
      country: allSearchParams.country,
      platform: allSearchParams.platform,
      startDate: allSearchParams.startDate,
      endDate: allSearchParams.endDate,
    },
  };
}

const InitialParams = {
  page: '0',
  size: String(DEFAULT_INITIAL_PAGE_SIZE),
  AnonymousUsers: IncludeExcludeAll.Exclude,
  orderBy: 'totalUsers',
  sortOrder: 'desc',
  startDate: CommonDateStrings.sevenDaysAgo.date,
  endDate: CommonDateStrings.today.date,
};
