import { DeleteNftPack } from 'src/components/modules/NFTPacks/services/deleteNftPack';
import useNotify from 'src/lib/hooks/useNotify';
import useModalStore from 'src/lib/stores/useModalStore';
import { ShardEmitter } from '../../events/ShardEmitter';

export default function useDeleteNftPack() {
  const closeModal = useModalStore((state) => state.closeModal);
  const notify = useNotify();

  const handleDeleteNftPack = async (uuid: string) => {
    const { data, error } = await DeleteNftPack(uuid);
    if (error) {
      notify(error, { variant: 'error' });
      closeModal();
    } else {
      notify('Nft Pack deleted!', { variant: 'success' });
      closeModal();
      ShardEmitter.emit('ShardDeleted', data);
    }
  };

  return {
    handleDeleteNftPack,
  };
}
