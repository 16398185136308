import { Box, Container } from '@mui/material';
import Flex from 'src/components/shared/Flex';
import SectionDivider from 'src/components/shared/SectionDivider';
import { SGMChat } from 'src/lib/types/sgmChatMessage';
import HeaderBreadcrumbs from 'src/template/components/HeaderBreadcrumbs';
import MakerDialogStatsCardInfos from '../../components/MakerDialogStatsCardInfo/makerStatsCardInfos';
import ChatScreen from '../ChatScreen';
import useMakerDialogDetails from './useView';

interface MakerDialogDetailsProps {
  promptDialogUuid?: string;
  showHeader?: boolean;
}

export default function MakerDialogDetails({
  promptDialogUuid,
  showHeader = true,
}: MakerDialogDetailsProps) {
  const { data, messagesData, handleFetchMore } =
    useMakerDialogDetails(promptDialogUuid);

  return (
    <Container maxWidth="xl">
      {showHeader && (
        <HeaderBreadcrumbs heading="SGM Chat" links={[{ name: 'SGM Chat' }]} />
      )}

      <MakerDialogStatsCardInfos chat={data} />

      <SectionDivider section="Chat" />

      <Flex justifyContent={'center'} alignItems={'none'}>
        <Box maxWidth={'600px'} maxHeight={'800px'}>
          <ChatScreen
            chatInfo={data ?? ({} as SGMChat)}
            messages={messagesData ?? []}
            loadMoreMessages={handleFetchMore}
            isLoading={messagesData?.length === 0}
            flexProps={{
              borderRadius: '16px',
            }}
          />
        </Box>
      </Flex>
    </Container>
  );
}
