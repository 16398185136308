import { Container } from '@mui/material';
import CardShards from 'src/components/modules/CardShards/view/List';
import Page from 'src/template/components/Page';

export default function CardShardsPage() {
  return (
    <Page title="Card Shards">
      <Container maxWidth="xl">
        <CardShards />
      </Container>
    </Page>
  );
}
