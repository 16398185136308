import { NFTPackFormValues } from 'src/components/modules/NFTPacks/components/Forms/NftPack/types';
import { CreateNftpack } from 'src/components/modules/NFTPacks/services/createNftPack';
import useNotify from 'src/lib/hooks/useNotify';
import useModalStore from 'src/lib/stores/useModalStore';
import { ShardEmitter } from '../../events/ShardEmitter';

export default function useCreateNFTPackView() {
  const closeModal = useModalStore((state) => state.closeModal);
  const notify = useNotify();

  const handleCreateNftPack = async (values: NFTPackFormValues) => {
    const { error, data } = await CreateNftpack({ ...values });

    if (error) {
      notify(error, { variant: 'error' });
      closeModal();
    } else if (data) {
      notify('Nft Pack Created!', { variant: 'success' });
      closeModal();

      ShardEmitter.emit('ShardCreated', null);
    }
  };

  return {
    handleCreateNftPack,
  };
}
