import {
  Alert,
  Autocomplete,
  Grid,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { GridColumns } from '@mui/x-data-grid';
import Center from 'src/components/shared/Center';
import Flex from 'src/components/shared/Flex';
import Link from 'src/components/shared/Link';
import MagicTable from 'src/components/shared/MagicTable/MagicTable';
import useMagicTable from 'src/components/shared/MagicTable/useMagicTable';
import NumberInfo from 'src/components/shared/NumberInfo/NumberInfo';
import SectionDivider from 'src/components/shared/SectionDivider';
import { CommonDateStrings } from 'src/lib/constants/CommonDates';
import { countries } from 'src/lib/constants/UserRegions';
import useURLSearchParams from 'src/lib/hooks/useURLSearchParams';
import Routes from 'src/routes/Routes';
import { SubscriptionReportTypeProductRows } from '../../../types/Subscription';
import useSubscriptionSalesView from './useView';

function CustomFooter() {
  const { data } = useSubscriptionSalesView();
  return (
    <Flex gap={2} justifyContent="left">
      <Grid
        container
        spacing={0}
        sx={{
          fontWeight: '600',
          mb: '20px',
          borderBottomLeftRadius: '8px',
          borderBottomRightRadius: '8px',
          minHeight: '56px',
          maxHeight: '56px',
          lineHeight: '56px',
        }}
        alignItems="center"
      >
        <Grid xs={4} sx={{ px: '10px' }}>
          Total
        </Grid>
        <Grid xs={2} sx={{ px: '10px' }}>
          {data?.totalizersFromViewedValues.total || 0}
        </Grid>
        <Grid xs={2} sx={{ px: '10px' }}>
          {data?.totalizersFromViewedValues.paid || 0}
        </Grid>
        <Grid xs={2} sx={{ px: '10px' }}>
          {data?.totalizersFromViewedValues.freeTrial || 0}
        </Grid>
        <Grid xs={2} sx={{ px: '10px' }}>
          {data?.totalizersFromViewedValues.totalWithKnownUsers || 0}
        </Grid>
      </Grid>
    </Flex>
  );
}

export default function SubscriptionSalesView(): JSX.Element {
  const magicTableProps = useMagicTable();
  const { data, error, isLoading, onChangeFilter, filter, totalizers } =
    useSubscriptionSalesView();

  const rows = data?.productCounters || [];

  if (error || (!isLoading && !data)) {
    return (
      <Center>
        <Alert severity="error">
          Could not load Products Subscriptions, please try again later.
          <br /> <b>Error:</b> {error}
        </Alert>
      </Center>
    );
  }

  return (
    <Stack spacing={3}>
      {/* <SectionDivider section="Total of subscriptions Purchased or Renewed all time" /> */}
      <Flex gap={2} justifyContent="left">
        <NumberInfo
          title="Total purchases/renewals"
          total={totalizers.data?.totalRegisters || 0}
        />
        <NumberInfo
          title="Total purchases/renewals with Known Users"
          total={totalizers.data?.totalRegistersWithUsers || 0}
        />
        <NumberInfo
          title="Paid purchases/renewals"
          total={totalizers.data?.totalPaid || 0}
        />
        <NumberInfo
          title="Free Trial purchases"
          total={totalizers.data?.totalFreeTrial || 0}
        />
      </Flex>
      <Typography textAlign="left" color="text.secondary" variant="body2">
        Above valuers refer to number of subscriptions purchased or renewed from
        2022 up to now
      </Typography>

      <SectionDivider section="Dates are in UTC timezone" />
      <MagicTable.Container>
        <MagicTable.FilterContainer>
          <Autocomplete
            options={countries}
            fullWidth
            renderInput={(params) => <TextField label="Country" {...params} />}
            loading={isLoading}
            defaultValue={filter.country}
            onChange={(_, value) => {
              onChangeFilter('country', value);
            }}
          />
          <TextField
            label="Starting Date"
            fullWidth
            type="date"
            InputLabelProps={{ shrink: true }}
            defaultValue={filter.startDate}
            inputProps={{
              min: CommonDateStrings.firstRevenueEventV2EventDate.date,
              max: CommonDateStrings.today.date,
            }}
            onChange={({ target: { value } }) => {
              onChangeFilter('startDate', value);
            }}
          />
          <TextField
            label="Ending Date"
            fullWidth
            type="date"
            InputLabelProps={{ shrink: true }}
            defaultValue={filter.endDate}
            inputProps={{
              min: filter.startDate,
              max: CommonDateStrings.today.date,
            }}
            onChange={({ target: { value } }) => {
              onChangeFilter('endDate', value);
            }}
          />
        </MagicTable.FilterContainer>
        <MagicTable.Wrapper>
          <MagicTable
            loading={isLoading}
            rows={rows}
            disableSelectionOnClick
            hideFooterPagination
            columns={columns}
            getRowId={(r) => r.productUuid}
            components={{
              Footer: CustomFooter,
            }}
            {...magicTableProps}
          />
        </MagicTable.Wrapper>
      </MagicTable.Container>
    </Stack>
  );
}

const columns: GridColumns<SubscriptionReportTypeProductRows> = [
  {
    field: 'productName',
    headerName: 'Product',
    flex: 2,
    renderCell({ row, value }) {
      const { allSearchParams } = useURLSearchParams();

      if (value) {
        return (
          <Stack>
            <Typography>{value}</Typography>
            <Typography fontSize="12px" color="GrayText">
              <Link
                underline="always"
                color="inherit"
                to={`${Routes.subscriptions_sales_v2(row.productUuid)}${
                  allSearchParams.endDate
                    ? `&endDate=${allSearchParams.endDate}`
                    : ''
                }`}
              >
                {row.productId}
              </Link>
            </Typography>
          </Stack>
        );
      }
      return row.productId;
    },
  },
  {
    field: 'total',
    headerName: 'Total',
    align: 'left',
  },
  {
    field: 'paid',
    headerName: 'Paid',
    align: 'left',
  },
  {
    field: 'freeTrial',
    headerName: 'Free Trial',
    align: 'left',
  },
  {
    field: 'knowedUsers',
    headerName: 'Known Users',
    align: 'left',
    renderCell({ value, row }) {
      return (
        <>
          {value}
          <Typography component="span" fontSize="12px" color="GrayText" ml={1}>
            (
            {Intl.NumberFormat('en-US', {
              style: 'percent',
              maximumFractionDigits: 2,
            }).format(value / row.total)}
            )
          </Typography>
        </>
      );
    },
  },
];
