import { BoxProps } from '@mui/material';
import Flex from 'src/components/shared/Flex';
import { SGMChat, SGMChatMessage } from 'src/lib/types/sgmChatMessage';
import LoadingScreen from 'src/template/components/LoadingScreen';
import ChatHeader from './ChatHeader';
import ChatMessages from './ChatMessages';

interface ChatScreenProps {
  chatInfo: SGMChat;
  messages: SGMChatMessage[];
  loadMoreMessages?: () => void;
  isLoading: boolean;
  flexProps?: BoxProps;
}

export default function ChatScreen({
  chatInfo,
  messages,
  loadMoreMessages,
  isLoading,
  flexProps,
}: ChatScreenProps) {
  if (isLoading) {
    return (
      <Flex
        width="100%"
        justifyContent="center"
        alignItems={'center'}
        bgcolor={'#000000'}
        {...flexProps}
      >
        <LoadingScreen />
      </Flex>
    );
  }

  return (
    <Flex
      flex="1"
      width="100%"
      height={'100%'}
      overflow="auto"
      bgcolor={'#000000'}
      flexDirection={'column'}
      alignItems={'center'}
      {...flexProps}
    >
      <ChatHeader chatInfo={chatInfo} />
      <ChatMessages
        chatInfo={chatInfo}
        messages={messages}
        loadMoreMessages={loadMoreMessages}
      />
    </Flex>
  );
}
